import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

function Home() {
    return (
        <div>
            <p>
                <span>Gude 05ER, </span>
                <br />
                auf dieser Seite kannst du deine digitale Identität
                verwalten, mit deinem Verein in Kontakt treten und deine
                Mitgliedschaft verwalten.
            </p>
            <div className="btn-group">
                <Link to="/kontakt">
                    <input
                        type="button"
                        value="Mitteilung an Deinen Verein"
                        style={{ marginBottom: 8 }}
                    />
                </Link>
                <a href="https://mein.mainz05.de/mitgliedschaft">
                    <input
                        type="button"
                        value="Mitgliedschaft beantragen"
                        style={{ marginBottom: 8 }}
                    />
                </a>
                <Link to="/kontaktdaten">
                    <input
                        type="button"
                        value="Kontaktdaten ändern"
                        style={{ marginBottom: 8 }}
                    />
                </Link>
                <Link to="/dsgvo-auskunft">
                    <input
                        type="button"
                        value="Auskunft über meine Daten"
                        style={{ marginBottom: 8 }}
                    />
                </Link>
            </div>
        </div>
    );
}

export default Home;
