import React, {useState, useEffect} from 'react'

export default (props) => {

    const [countPackages, setCountPackes] = useState(1)
    const [chosenPackages, setChosenPackages] = useState([])
    const [disabled, setDisabled] = useState([false, false, false])
    const [checked, setChecked] = useState([false, false, false])
    const [infoShown, setInfoShown] = useState([false, false, false])
    const [selected, setSelected] = useState("")

    const packages = [
        'Gute Laune',
        'Rot & Weiß',
        'Kids'
    ]

    const infos = [
        '6ER Schobbe, 2x Schobbeglas und eine Weinüberraschung vom Weingut Kühn',
        'Schal rot-weiß, Pommes rot-weiß in der MEWA ARENA und Autogrammkartensatz',
        `Kinder-T-Shirt, 05ER Brotdose & eine süße Gutscheinüberraschung`
    ]

    useEffect(()=> {
        if (chosenPackages.length === countPackages) {
            //TODO: disable all others
        }
        if (props.onPresentChange) {
            props.onPresentChange(selected)
        }
    }, [selected])

    const onPackageChange = (evt) => {
        setSelected(packages[evt.target.id])
    }

    const renderInfo = (id) => {
        if (infoShown[id]) {
            return (
                <div>
                    {infos[id]}
                </div>
            )
        }
        return undefined
    }

    return (
        <div>
            <div>
                {packages.map((p,k) => {
                    return (
                        <div key={k}>
                            <label>
                                <input type="radio" name={props.name} id={k} value={selected} onChange={onPackageChange}/>&nbsp;
                                Paket&nbsp;
                                "{p}"
                                &nbsp;
                                <a href="#" id={k} onClick={(evt) => {
                                    evt.preventDefault()
                                    const info = [...infoShown]
                                    info[k] = !info[k]
                                    setInfoShown(info)
                                }}>Details</a>
                            </label>
                            <div>
                                {renderInfo(k)}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}