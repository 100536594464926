import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

function Kontakt() {
    const history = useHistory();
    const [tooltip, setTooltip] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [view, setView] = useState(0);

    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [theme, setTheme] = useState("0");
    const [message, setMessage] = useState("");

    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isMailOrPhoneEmpty, setIsMailOrPhoneEmpty] = useState(false);
    const [isThemeEmpty, setIsThemeEmpty] = useState(false);

    function submit() {
        if (name && (validateEmail() || phone) && theme != "0") {
            setSubmitDisabled(true);
            axios
                .post("/kontakt", {
                    name,
                    mail,
                    phone,
                    theme,
                    message
                })
                .then(function() {
                    setView(1);
                })
                .catch(function(error) {
                    setSubmitDisabled(false);
                    console.log(error);
                });
        }
        if (!name) {
            setIsNameEmpty(true);
        }
        if (theme == "0") {
            setIsThemeEmpty(true);
        }
        if (!validateEmail() && !phone) {
            setIsMailOrPhoneEmpty(true);
        }
    }

    function validateEmail() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    return (
        <div>
            {view == 0 && (
                <div>
                    <h2>Mitteilung an deinen Verein</h2>
                    <div className="chat">
                        {tooltip && (
                            <span>
                                Gude 05ER, nutze unseren Chatbot von Userlike
                                unten rechts.
                            </span>
                        )}
                        <input
                            type="button"
                            value="Chat"
                            style={{ marginBottom: 16 }}
                            onMouseEnter={() => setTooltip(true)}
                            onMouseLeave={() => setTooltip(false)}
                            className="btn-light"
                        />
                    </div>
                    <label style={{ marginBottom: 16 }}>
                        Name
                        <br />
                        <input
                            type="text"
                            className={isNameEmpty ? "error" : ""}
                            placeholder="Vorname und Nachname"
                            value={name}
                            onChange={e => {
                                if (isNameEmpty && e.target.value) {
                                    setIsNameEmpty(false);
                                }
                                setName(e.target.value);
                            }}
                        />
                    </label>
                    <label>
                        E-Mail-Adresse
                        <br />
                        <input
                            type="text"
                            style={{ marginBottom: 16 }}
                            value={mail}
                            className={isMailOrPhoneEmpty ? "error" : ""}
                            onChange={e => {
                                if (isMailOrPhoneEmpty && e.target.value) {
                                    setIsMailOrPhoneEmpty(false);
                                }
                                setMail(e.target.value);
                            }}
                        />
                    </label>
                    <label>
                        Telefonnummer
                        <br />
                        <input
                            type="text"
                            className={
                                isMailOrPhoneEmpty && !mail ? "error" : ""
                            }
                            style={{ marginBottom: 16 }}
                            value={phone}
                            onChange={e => {
                                if (isMailOrPhoneEmpty && e.target.value) {
                                    setIsMailOrPhoneEmpty(false);
                                }
                                setPhone(e.target.value);
                            }}
                        />
                    </label>
                    <label>
                        Dein Anliegen
                        <br />
                        <select
                            className={isThemeEmpty ? "error" : ""}
                            defaultValue="0"
                            onChange={e => {
                                if (isThemeEmpty && e.target.value != "0") {
                                    setIsThemeEmpty(false);
                                }
                                setTheme(e.target.value);
                            }}
                            style={{ marginBottom: 16 }}
                        >
                            <option value="0" disabled hidden>
                                Bitte wähle Dein Anliegen aus.
                            </option>
                            <option value="Allgemeine Frage/Nachricht">
                                Allgemeine Frage/Nachricht
                            </option>
                            <option value="Rückrufbitte">Rückrufbitte</option>
                            <option value="Fragen/Nachricht zu Eintrittskarten">
                                Fragen/Nachricht zu Eintrittskarten
                            </option>
                            <option value="Fragen/Nachricht zur Dauerkarte">
                                Fragen/Nachricht zur Dauerkarte
                            </option>
                            <option value="Fragen/Nachricht zur Mitgliedschaft">
                                Fragen/Nachricht zur Mitgliedschaft
                            </option>
                            <option value="Fragen/Nachricht zum Fußball">
                                Fragen/Nachricht zum Fußball
                            </option>
                            <option value="Fragen/Nachricht zum Handball">
                                Fragen/Nachricht zum Handball
                            </option>
                            <option value="Fragen/Nachricht zu eSports">
                                Fragen/Nachricht zu eSports
                            </option>
                            <option value="Fragen/Nachricht zur Fanabteilung">
                                Fragen/Nachricht zur Fanabteilung
                            </option>
                            <option value="Sonstige Fragen/Nachricht">
                                Sonstige Fragen/Nachricht
                            </option>
                        </select>
                    </label>
                    <label>
                        Frage/Nachricht
                        <br />
                        <textarea
                            style={{ marginBottom: 16 }}
                            placeholder="Deine Frage / Nachricht an Mainz 05..."
                            value={message}
                            onChange={e => {
                                setMessage(e.target.value);
                            }}
                        />
                    </label>
                    {isMailOrPhoneEmpty && (
                        <div className="error" style={{ marginBottom: 16 }}>
                            Bitte geben Sie mindestens eine E-Mail-Adresse oder
                            Telefonnummer an
                        </div>
                    )}
                    <input
                        type="button"
                        value="Absenden"
                        style={{ marginBottom: 8 }}
                        onClick={() => submit()}
                        disabled={submitDisabled}
                    />
                    <input
                        type="button"
                        value="Zurück"
                        className="back"
                        onClick={() => history.push("/")}
                    />
                    <div className="btn-group-inline">
                        <a href="tel:+496131375500">
                            <input
                                type="button"
                                value="Anruf"
                                className="btn-light"
                            />
                        </a>
                        <a href="tel:+496131375501905">
                            <input
                                type="button"
                                value="Tickethotline"
                                className="btn-light"
                            />
                        </a>
                    </div>
                </div>
            )}
            {view == 1 && (
                <div>
                    <h2>Mitteilung an deinen Verein</h2>
                    <p>
                        Vielen Dank, deine Nachricht wurde erfolgreich versandt.
                    </p>
                    <input
                        type="button"
                        value="Ok"
                        style={{
                            marginTop: 8,
                            marginBottom: 8
                        }}
                        onClick={() => history.push("/")}
                    />
                </div>
            )}
        </div>
    );
}

export default Kontakt;
