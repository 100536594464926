import React, { useState } from "react";
import { useHistory } from "react-router-dom";

function Kontaktdaten() {
    return (
        <div>
            <h2>Kontaktdaten ändern</h2>
            <label style={{ marginBottom: 16 }}>
                Ändere deine Kontaktdaten in unserem Onlineshop unter&nbsp;
                <a href="https://shop.mainz05.de/index.php?cl=account">
                    shop.mainz05.de
                </a>
            </label>
            <a href="https://shop.mainz05.de/index.php?cl=account">
                <input
                    type="button"
                    value="Kontaktdaten ändern"
                    style={{ marginBottom: 8 }}
                />
            </a>
            <label style={{ marginBottom: 16, fontSize: "0.9rem" }}>
                Wir ändern für dich daraufhin auch deine Kontaktdaten in unserm
                Online-Ticket-Shop bei Eventim binnen 48h.
            </label>
        </div>
    );
}

export default Kontaktdaten;
