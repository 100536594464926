import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import axios from "axios";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import countriesList from "../countries";
import IBANService from "../iban";
import moment from 'moment'

import Aktion from './aktion'

registerLocale("de", de);
setDefaultLocale("de");
moment.locale('de')

function Mitgliedschaft(props) {
    const history = useHistory();

    const [type, setType] = useState(props.type || 'default')
    const [view, setView] = useState(1);
    const [showPriceTable, setShowPriceTable] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const [price, setPrice] = useState(0);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [birthdate, setBirthdate] = useState();
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [gender, setGender] = useState("0");
    const [country, setCountry] = useState(countriesList["DE"]);
    const [countries] = useState(countriesList);
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [club, setClub] = useState([
        { id: 1, name: "Fußball", isChecked: true },
        { id: 2, name: "Handball", isChecked: false },
        { id: 3, name: "Tischtennis", isChecked: false },
        { id: 4, name: "Fanabteilung", isChecked: false, isDisabled: false }
    ]);
    const [typeSubscription, setTypeSubscription] = useState("0");
    const [kidsNumber, setKidsNumber] = useState(1);
    const [blankKid, setBlankKid] = useState({
        firstname: "",
        lastname: "",
        birthdate: null,
        gender: "0",
        error: false
    });
    const [kids, setKids] = useState();
    const [partnerFirstname, setPartnerFirstname] = useState("");
    const [partnerLastname, setPartnerLastname] = useState("");
    const [partnerGender, setPartnerGender] = useState("0");
    const [partnerBirthdate, setPartnerBirthdate] = useState();
    const [singleParent, setSingleParent] = useState(false);
    const [accountHolder, setAccountHolder] = useState("");
    const [IBAN, setIBAN] = useState("");
    const [BIC, setBIC] = useState("");
    const moneyCheckbox = useRef();
    const termsCheckbox = useRef();
    const newsCheckbox = useRef();
    const presentCheckbox = useRef();
    const [requestSuccess, setRequestSuccess] = useState(false);

    const [isGenderEmpty, setIsGenderEmpty] = useState(false);
    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isBirthdateEmpty, setIsBirthdateEmpty] = useState(false);
    const [isMailOrPhoneEmpty, setIsMailOrPhoneEmpty] = useState(false);
    const [isPartnerFirstnameEmpty, setIsPartnerFirstnameEmpty] = useState(
        false
    );
    const [isPartnerLastnameEmpty, setIsPartnerLastnameEmpty] = useState(false);
    const [isPartnerGenderEmpty, setIsPartnerGenderEmpty] = useState(false);
    const [isPartnerBirthdateEmpty, setIsPartnerBirthdateEmpty] = useState(
        false
    );
    const [isZIPWrong, setIsZIPWrong] = useState(false);
    const [isClubEmpty, setIsClubEmpty] = useState(false);
    const [isCityEmpty, setIsCityEmpty] = useState(false);
    const [isTypeSubscriptionEmpty, setIsTypeSubscriptionEmpty] = useState(
        false
    );
    const [isAccountHolderEmpty, setIsAccountHolderEmpty] = useState(false);
    const [isIBANEmpty, setIsIBANEmpty] = useState(false);
    const [isIBANWrong, setIsIBANWrong] = useState(false);
    const [isBICEmpty, setIsBICEmpty] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(true);

    const [isPresent, setIsPresent] = useState(props.type === 'present' || false);
    const [isReferral, setIsReferral] = useState(props.type === 'referral' || false);

    const [giverName, setGiverName]  = useState("")
    const [giverNameEmpty, setGiverNameEmpty] = useState(false)
    const [giverFirstname, setGiverFirstname] = useState("")
    const [giverFirstnameEmpty, setGiverFirstnameEmpty] = useState(false)
    const [giverStreet, setGiverStreet] = useState("")
    const [giverStreetEmpty, setGiverStreetEmpty] = useState(false)
    const [giverPLZ, setGiverPLZ] = useState("")
    const [giverPLZInvalid, setGiverPLZInvalid] = useState(false)
    const [giverCity, setGiverCity] = useState("")
    const [giverCityEmpty, setGiverCityEmpty] = useState(false)
    const [giverEmail, setGiverEmail] = useState("")
    const [giverEmailEmpty, setGiverEmailEmpty] = useState(false)
    const [giverPhone, setGiverPhone] = useState("")
    const [giverCountry, setGiverCountry] = useState(countriesList["DE"]);

    const [giverCollapse, setGiverCollapse] = useState(true)

    const [referralName, setReferralName] = useState("")
    const [referralNameEmpty, setReferralNameEmpty] = useState(false)
    const [referralFirstname, setReferralFirstname] = useState("")
    const [referralFirstnameEmpty, setReferralFirstnameEmpty] = useState(false)
    const [referralMemberId, setReferralMemberId] = useState("")
    const [referralMemberIdInvalid, setReferralMemberIdInvalid] = useState(false)

    const [termsAccepted, setTermsAccepted] = useState(false)
    const [newsletterAccepted, setNewsletterAccepted] = useState(false)
    const [paymentAccepted, setPaymentAccepted] = useState(false)

    const [showFAQ, setShowFAQ] = useState(false)

    const [remainingPrice, setRemainingPrice] = useState(0.0)
    const [regularContractStart, setRegularContractStart] = useState(moment())
    const [nextBilling, setNextBilling]  = useState(moment())

    const [devDate, setDevDate] = useState()

    const [presentOne, setPresentOne] = useState()
    const [presentOneSet, setPresentOneSet] = useState(false)
    const [presentTwo, setPresentTwo] = useState()
    const [presentTwoSet, setPresentTwoSet] = useState(false)

    const [cameFrom, setCameFrom] = useState("")
    const [cameFromError, setCameFromError] = useState(false)
    const [cameFromFetched, setCameFromFetched] = useState(false)
    const [cameFromOptions, setCameFromOptions] = useState([
        "In der Arena/im Stadion",
        "Fanclubtreffen",
        "Auswärtsfahrten",
        "Familie, Freunde oder Bekannte",
        "Andere Veranstaltungen von Mainz 05",
        "Fanshop von Mainz 05",
        "Homepage",
        "Mailing/Newsletter",
        "Social-Media-Kanäle",
        "Flyer zum Thema Mitgliedschaft",
        "Keine Angabe"
    ])
    const [handschuhSize, setHandschuhSize] = useState("M")
    const [handschuhError, setHandschuehError] = useState(false)

    const endeGeschenk = moment("2022-12-23 00:00:00")
    const endeRefGes = moment("2023-03-16 00:00:00")

    const onHandschuhChange = (evt) => {
        setHandschuhSize(evt.target.value)
        setHandschuehError(false)
    }

    const onCameFromchange = (evt) => {
        setCameFrom(evt.target.value)
        setCameFromError(false)
    }

    const onPresentOneChange = (present) => {
        // console.log('Main', present)
        setPresentOne(present)
    }

    const onPresentTwoChange = (present) => {
        // console.log('Main p2', present)
        setPresentTwo(present)
    }
    const location = useLocation()
    
    useEffect(() => {
        if (!cameFromFetched) {
            axios.get('https://util.mainz05.de/fums/camefrom.txt').then((res) => {
                // setCameFrom(res.data.cameFrom)
                const tmp = res.data.replaceAll("\n", "").split(",")
                tmp.push("Keine Angabe")
                setCameFromOptions(tmp)
                setCameFromFetched(true)
            })
            .catch(err => {
                console.log(err)
            })
        }
        if (location.search !== '') {
            if (!firstname) {
                const sp = new URLSearchParams(location.search)
                const ding = []
                sp.forEach((vl, key) => {
                    if (key === 'vorname')
                        setFirstname(vl)
                    if (key === 'nachname')
                        setLastname(vl)
                    if (key === 'plz')
                        setZip(vl)
                    if (key === 'strasse')
                        setStreet(vl)
                    if (key === 'email')
                        setMail(vl)
                    if (key === 'geburtstag')
                        setBirthdate(vl)
                    if (key === 'stadt')
                        setCity(vl)
                    if (key === 'werber')
                        setIsReferral(true)
                    if (key==='refFirstname')
                        setReferralFirstname(vl)
                    if (key==='refLastname')
                        setReferralName(vl)
                    if (key==='refMembership') {
                        setReferralMemberId(vl)
                    }
                })
            }
        }
        if (isPresent) {
            if (moment().diff(endeGeschenk)<0)
                setType('present')
            else {
                setIsPresent(false)
            }
        } else if (isReferral) {
            setType('referral')
        }
        else if (type !== 'default') {
            setType('default')
            console.log('default change und so')
        }
        if (!birthdate) {
            setPrice(0);
            return;
        }
        let today = new Date(Date.now());
        let d1 = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        let d2 = new Date(birthdate);

        // let age = today.getFullYear() - d2.getFullYear();
        // console.log({age, d1, d2, today, d1FullYear: d1.getFullYear(), todayFullYear: today.getFullYear()})
        let age = moment().diff(moment(birthdate), 'y')
        let tempPrice = 0;
        if (typeSubscription == "0" && age > 67) {
            setTypeSubscription("ermäßigte Einzelmitgliedschaft");
        }
        if (typeSubscription != "Familienmitgliedschaft") {
            tempPrice = getPriceForAge(age);
            if (
                tempPrice > 60 &&
                typeSubscription == "ermäßigte Einzelmitgliedschaft"
            ) {
                tempPrice = 60;
            }
            if (presentTwo !== undefined) {
                setPresentTwo(undefined)
            }
        } else {
            let payedKids = 0;
            for (let i = 0; i < kids.length; i++) {
                let dk1 = new Date(
                    today.getFullYear(),
                    today.getMonth() + 1,
                    1
                );
                let dk2 = new Date(kids[i].birthdate);
                let kidAge = dk1.getFullYear() - dk2.getFullYear();
                if (kidAge >= 7) {
                    ++payedKids;
                }
            }
            if (payedKids !== 0) {
                --payedKids;
            }

            if (singleParent && kids.length) {
                tempPrice = 75 + payedKids * 10;
            } else if (!singleParent && kids.length) {
                tempPrice = 150 + payedKids * 10;
            } else if (!singleParent && !kids.length) {
                tempPrice =
                    getPriceForAge(age) +
                    getPriceForAge(
                        moment().diff(moment(partnerBirthdate), 'y')
                    );
            } else {
                tempPrice = getPriceForAge(age);
            }
        }
        setPrice(tempPrice);
        if (isPresent) {
            setRemainingPrice(tempPrice)
        } else {
            setRemainingPrice(calculateRemainingPrice(tempPrice))
        }

        if ((termsAccepted || isPresent) && paymentAccepted && submitDisabled) {
            setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }
    }, [
        birthdate,
        singleParent,
        typeSubscription,
        kidsNumber,
        partnerBirthdate,
        kids,
        isPresent,
        isReferral,
        paymentAccepted,
        termsAccepted,
        cameFromFetched
    ]);

    const calcMonthDiff = (current = moment()) => {
        return (current.month()-((current.quarter()-1)*3))
    }

    function getPriceForAge(age) {
        if (age < 7) {
            return 0;
        } else if (6 < age && age < 13) {
            setRemainingPrice(calculateRemainingPrice(19.05))
            return 19.05;
        } else if (12 < age && age < 19) {
            setRemainingPrice(calculateRemainingPrice(19.05))
            return 19.05;
        } else if (18 < age && age < 26) {
            setRemainingPrice(calculateRemainingPrice(39.05))
            return 39.05;
        } else if (25 < age && age < 68) {
            setRemainingPrice(calculateRemainingPrice(78))
            return 78;
        } else if (67 < age) {
            setRemainingPrice(calculateRemainingPrice(60))
            return 60;
        } else {
            setRemainingPrice(calculateRemainingPrice(60))
            return 60;
        }
    }
    //TODO: CalcRemain
    function calculateRemainingPrice(price) {
        const now = moment()
        const months = calcMonthDiff(now)
        if (isPresent) {
            return price
        }
        if (months > 0) {
            setRegularContractStart(now.subtract(months, 'month'))
            setNextBilling(regularContractStart.add(1, 'year'))
        }
        if (months === 0) {
            setRegularContractStart(now.subtract(months, 'month'))
            setNextBilling(regularContractStart.add(1, 'year'))
            return price;
        }
        return (price/12)*(12-months)
    }

    function next() {
        if (
            gender != "0" &&
            firstname &&
            lastname &&
            birthdate &&
            (validateEmail() || phone) &&
            city &&
            validateZIP() &&
            cameFrom !== ""
        ) {
            if (!partnerLastname) setPartnerLastname(lastname);

            if (!kids) {
                let updatedBlank = {
                    firstname: "",
                    lastname: lastname,
                    birthdate: null,
                    gender: "0",
                    error: false
                };
                setBlankKid(updatedBlank);
                setKids([{ ...updatedBlank }]);
            }
            if (typeSubscription == "0")
                setTypeSubscription("Einzelmitgliedschaft");

            if (!accountHolder && !isPresent) 
                setAccountHolder(firstname + " " + lastname);
            else if (!accountHolder && isPresent)
                setAccountHolder(giverFirstname + " " + giverName) 

            // dataLayer.push({
            //     event: 'mitgliedschaft weiter',
            //     screen: 'debitview',
            //     price,
            //     referalFrom: cameFrom
            // })
            setView(2);
        } else {
            if (gender == "0") setIsGenderEmpty(true);

            if (!firstname) setIsFirstnameEmpty(true);

            if (!lastname) setIsLastnameEmpty(true);

            if (!birthdate) setIsBirthdateEmpty(true);

            if (!city) setIsCityEmpty(true);

            if (!validateEmail() && !phone) setIsMailOrPhoneEmpty(true);

            if (!validateZIP()) setIsZIPWrong(true);
            if (cameFrom === "") {
                setCameFromError(true)
            }
        }
    }

    function summary() {
        if (
            accountHolder &&
            IBAN &&
            IBANService.isValid(IBAN) &&
            validateClubs() &&
            typeSubscription != "0" &&
            (typeSubscription != "Familienmitgliedschaft" ||
                (!singleParent &&
                    partnerFirstname &&
                    partnerLastname &&
                    partnerBirthdate &&
                    validateKids()) ||
                (singleParent && validateKids()))
        ) {
            setView(3);
            // dataLayer.push({
            //     event: 'mitgliedschaft weiter',
            //     screen: 'summary',
            //     price,
            //     referalFrom: cameFrom
            // })
        } else {
            if (!accountHolder) {
                setIsAccountHolderEmpty(true);
            }
            if (!IBAN) {
                setIsIBANEmpty(true);
            }
            if (IBAN && !IBANService.isValid(IBAN)) {
                setIsIBANWrong(true);
            }
            if (typeSubscription == "0") {
                setIsTypeSubscriptionEmpty(true);
            }
            if (
                !partnerFirstname &&
                typeSubscription == "Familienmitgliedschaft" &&
                !singleParent
            ) {
                setIsPartnerFirstnameEmpty(true);
            }
            if (
                !partnerLastname &&
                typeSubscription == "Familienmitgliedschaft" &&
                !singleParent
            ) {
                setIsPartnerLastnameEmpty(true);
            }
            if (
                !partnerGender == "0" &&
                typeSubscription == "Familienmitgliedschaft" &&
                !singleParent
            ) {
                setIsPartnerGenderEmpty(true);
            }
            if (
                !partnerBirthdate &&
                typeSubscription == "Familienmitgliedschaft" &&
                !singleParent
            ) {
                setIsPartnerBirthdateEmpty(true);
            }
            if (typeSubscription == "Familienmitgliedschaft") {
                validateKids();
            }
            validateClubs();
        }
    }

    function submit() {
        if ((termsAccepted || isPresent) && paymentAccepted) {
            const payload = {
                gender,
                firstname,
                name: firstname + " " + lastname,
                    birthdate: getDateString(birthdate),
                    address:
                        (street ? street + ", " : "") +
                        (zip ? zip + " " : "") +
                        city +
                        ", " +
                        country,
                    street,
                    zip,
                    city,
                    country,
                    mail,
                    phone,
                    clubs: club.map(el => {
                        if (el.isChecked) {
                            return el.name;
                        }
                    }),
                    subscription: typeSubscription,
                    partner:
                        !singleParent &&
                        typeSubscription == "Familienmitgliedschaft"
                            ? partnerFirstname +
                              " " +
                              partnerLastname +
                              ", " +
                              getDateString(partnerBirthdate)
                            : "",
                    kids:
                        kids.length &&
                        typeSubscription == "Familienmitgliedschaft"
                            ? kids.map(val => {
                                  return (
                                      val.firstname +
                                      " " +
                                      val.lastname +
                                      " (" +
                                      val.gender +
                                      "), " +
                                      getDateString(val.birthdate)
                                  );
                              })
                            : "",
                    accountHolder,
                    IBAN: IBAN,
                    moneyCheckbox: paymentAccepted,
                    newsCheckbox: newsletterAccepted,
                    price: price.toFixed(2),
                    type,
                    remainingPrice: remainingPrice.toFixed(2),
                    regularContractStart: regularContractStart.format("YYYY-MM-DD"),
                    nextBilling: nextBilling.format("YYYY-MM-DD"),
                    presentOne: (presentOne !== "" ? presentOne : undefined),
                    presentTwo: (presentTwo !== "" ? presentTwo : undefined),
                    quelle: cameFrom,
                    cameFrom
            }
            
            if (isPresent) {
                // TODO: do it xD
                console.log('present submit')
                payload.giver = {
                    giverFirstname,
                    giverName,
                    giverStreet,
                    giverPLZ,
                    giverCity,
                    giverEmail
                }
            }
            if (isReferral) {
                // TODO: next do it xD
                console.log('referral submit')
                payload.referrer = {
                    referralFirstname,
                    referralName,
                    referralMemberId
                }
            }
            if (moment().diff(endeRefGes, 's')<0)
                payload.groesse = handschuhSize
            else 
                payload.groesse = undefined
            // dataLayer.push({
            //     event: 'mitgliedschaft bezahlen',
            //     screen: 'complete',
            //     price,
            //     referalFrom: cameFrom
            // })
            // console.log(payload)
            // console.log(payload)
            axios
                .post("/mitgliedschaft", payload)
                .then(function(res) {
                    if (res.data.success) {
                        setRequestSuccess(true);

                        setView(4);
                        // dataLayer.push({
                        //     event: 'mitgliedschaft abgeschlossen',
                        //     price,
                        //     referalFrom: cameFrom,
                        //     success: true
                        // })
                    } else if (!res.data.success) {
                        setRequestSuccess(false);
                        setView(4);
                        // dataLayer.push({
                        //     event: 'mitgliedschaft abgeschlossen',
                        //     price,
                        //     referalFrom: cameFrom,
                        //     success: false
                        // })
                    }
                    // console.log(res);
                })
                .catch(function(error) {
                    setSubmitDisabled(false);
                    setRequestSuccess(false);
                    setView(4);
                });
        }
    }

    function getBIC(iban) {
        axios
            .get(
                `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=false`
            )
            .then(function(response) {
                if (response.data.bankData.bic)
                    setBIC(response.data.bankData.bic);
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    function handleClubChange(e) {
        let clubTemp = [...club];
        clubTemp.forEach(c => {
            if (c.name == e.target.value) {
                c.isChecked = !c.isChecked;
            }
        });
        if (
            clubTemp[0].isChecked ||
            clubTemp[1].isChecked ||
            clubTemp[2].isChecked
        ) {
            clubTemp[3].isDisabled = false;
        } else {
            clubTemp[3].isDisabled = true;
            clubTemp[3].isChecked = false;
        }
        setClub(clubTemp);
        setIsClubEmpty(false);
    }
    function validateEmail() {
        if (/^\w+([\.-]?\w+)*(\+?\w*)@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    function validateGiverEmail() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(giverEmail)) {
            return true;
        }
        return false;
    }

    function validateClubs() {
        let state = false;
        club.forEach(c => {
            if (c.isChecked == true) {
                state = true;
            }
        });
        if (!state) {
            setIsClubEmpty(true);
            window.scrollTo(0, 0);
        }
        return state;
    }

    function validateZIP() {
        if (zip.length == 0) {
            return true;
        }
        if (zip.length < 5) {
            return false;
        }
        let valInt = parseInt(zip, 10);
        if (1067 <= valInt && valInt <= 99998) {
            return true;
        }
        return false;
    }

    function validateGiverPLZ(value) {
        if (value.length > 0 && value.length < 6) {
            let valInt = parseInt(value, 10)
            if (1067 <= valInt && valInt <= 99998) 
                return true
        }
        return false
    }

    function changeKids(e) {
        const updatedKids = [...kids];
        if (e.target.value < 1) {
            e.target.value = 1;
        }
        if (kidsNumber > e.target.value) {
            for (let i = 0; i < kidsNumber - e.target.value; i++) {
                updatedKids.pop();
            }
            setKids(updatedKids);
        } else if (kidsNumber < e.target.value) {
            for (let i = 0; i < e.target.value - kidsNumber; i++) {
                updatedKids.push({ ...blankKid });
            }
            setKids(updatedKids);
        }
        setKidsNumber(e.target.value);
    }

    function removeKid(idx) {
        const updatedKids = [...kids];
        updatedKids.splice(idx, 1);
        setKids(updatedKids);
        setKidsNumber(kidsNumber - 1);
    }

    function handleKidChange(e) {
        const updatedKids = [...kids];
        updatedKids[e.target.dataset.idx][e.target.dataset.name] =
            e.target.value;
        if (
            updatedKids[e.target.dataset.idx]["firstname"] &&
            updatedKids[e.target.dataset.idx]["lastname"] &&
            updatedKids[e.target.dataset.idx]["gender"] &&
            updatedKids[e.target.dataset.idx]["birthdate"]
        ) {
            updatedKids[e.target.dataset.idx]["error"] = false;
        }
        setKids(updatedKids);
    }

    function validateKids() {
        let state = true;
        const updatedKids = [...kids];
        for (let i = 0; i < updatedKids.length; i++) {
            if (
                !updatedKids[i]["firstname"] ||
                !updatedKids[i]["lastname"] ||
                !updatedKids[i]["gender"] ||
                !updatedKids[i]["birthdate"]
            ) {
                updatedKids[i]["error"] = true;
                state = false;
            }
        }
        setKids(updatedKids);
        return state;
    }

    function getDateString(ms) {
        ms = new Date(ms);
        return (
            (ms.getDate() < 10 ? "0" + ms.getDate() : ms.getDate()) +
            "." +
            (ms.getMonth() + 1 < 10 ? "0" : "") +
            (ms.getMonth() + 1) +
            "." +
            ms.getFullYear()
        );
    }
    function devPresent() {
        if (isPresent) {
            console.log('jup')
        } else {
            console.log('nope')
        }
    }

    function renderGiverAccordion() {
        return (
            <div id="giverCollapse">
                
            </div>
        )
    }

    function renderCollapseIcon() {
        return (
            <div className='mz-clickable' onClick={collapseGiver}>
                {giverCollapse ? 
                    <i className="fas fa-chevron-up"></i>
                    :
                    <i className="fas fa-chevron-down"></i>
                }
            </div>
        )
    }

    function renderGiverInfo() {
        return (
            <div>
                <label style={{ marginBottom: 16 }}>
                    Vorname
                    <br />
                    <input
                        type="text"
                        value={giverFirstname}
                        className={
                            giverFirstnameEmpty ? "error" : ""
                        }
                        onChange={e => {
                            if (e.target.value.length < 1) {
                                setGiverFirstnameEmpty(true)
                            } else {
                                setGiverFirstnameEmpty(false)
                            }
                            setGiverFirstname(e.target.value);
                        }}
                    />
                </label>
                <label style={{ marginBottom: 16 }}>
                    Nachname
                    <br />
                    <input
                        type="text"
                        className={
                           giverNameEmpty ? "error" : ""
                        }
                        value={giverName}
                        onChange={e => {
                            if (e.target.value.length < 1) {
                                setGiverNameEmpty(true)
                            } else {
                                setGiverNameEmpty(false)
                            }
                            setGiverName(e.target.value);
                        }}
                    />
                </label>
                <label style={{ marginBottom: 16 }}>
                    Land
                    <br />
                    <select
                        defaultValue={giverCountry}
                        onChange={e => {
                            setGiverCountry(e.target.value);
                        }}
                    >
                        {Object.keys(countries).map(k => {
                            return (
                                <option
                                    value={countries[k]}
                                    key={k}
                                >
                                    {countries[k]}
                                </option>
                            );
                        })}
                    </select>
                </label>
                <div
                    style={{
                        marginBottom: 16,
                        display: "flex"
                    }}
                >
                    <label
                        style={{
                            paddingRight: 8,
                            flexBasis: "33%"
                        }}
                    >
                        PLZ
                        <br />
                        <input
                            type="text"
                            value={giverPLZ}
                            className={
                                giverPLZInvalid ? "error" : ""
                            }
                            onChange={e => {
                                if (validateGiverPLZ(e.target.value)) {
                                    setGiverPLZInvalid(false);
                                } else {
                                    setGiverPLZInvalid(true)
                                }
                                setGiverPLZ(
                                    e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    )
                                );
                            }}
                        />
                        {giverPLZInvalid && (
                            <div
                                className="error"
                                style={{
                                    width: "100%"
                                }}
                            >
                                Bitte PLZ prüfen
                            </div>
                        )}
                    </label>

                    <label style={{ flexBasis: "66%" }}>
                        Stadt
                        <br />
                        <input
                            type="text"
                            value={giverCity}
                            onChange={e => {
                                setGiverCity(e.target.value);
                            }}
                            className={
                                giverCity.length < 1 && giverCity.length > 0 ? "error" : ""
                            }
                        />
                    </label>
                </div>
                <label style={{ marginBottom: 16 }}>
                    Straße, Hausnummer
                    <br />
                    <input
                        type="text"
                        value={giverStreet}
                        onChange={e =>
                            setGiverStreet(e.target.value)
                        }
                    />
                </label>
                <label>
                    E-Mail-Adresse
                    <br />
                    <input
                        type="text"
                        style={{ marginBottom: 4 }}
                        value={giverEmail}
                        className={
                            giverEmailEmpty ? "error" : ""
                        }
                        onChange={e => {
                            if (validateGiverEmail) {
                                setGiverEmailEmpty(false)
                            } else {
                                setGiverEmailEmpty(true)
                            }
                            setGiverEmail(e.target.value);
                        }}
                    />
                </label>
            </div>
        )
    }

    function renderCheckboxes() {
        //TODO: Geschenkding
        if (type === 'default') {
            return (
                <div>
                    { moment().diff(endeGeschenk, 's') < 0 &&
                    <div>
                        <label
                            style={{
                                fontSize: '1,2em',
                                marginBottom: 8,
                                marginTop: 16
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={isPresent}
                                onChange={ e => {
                                    setIsPresent(!isPresent)
                                }}
                            />
                            Ich möchte eine Mitgliedschaft verschenken
                        </label>
                        <br />
                    </div>
                    }
                    <label
                        style={{
                            fontSize: '1,2em',
                            marginBottom: 8,
                            marginTop: 16
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={isPresent}
                            onChange={ e => {
                                setIsReferral(!isReferral)
                            }}
                        />
                        Ich wurde von einem Mitglied geworben
                    </label>
                </div>
            )
        }
        else if (type === 'present') {
            return (
                <div>
                    <label
                        style={{
                            fontSize: '1,2em',
                            marginBottom: 8,
                            marginTop: 16
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={isPresent}
                            onChange={ e => {
                                setIsPresent(!isPresent)
                            }}
                        />
                        Ich möchte eine Mitgliedschaft verschenken
                    </label>
                </div>
            )
        } else {
            return (
                <div>
                    <label
                        style={{
                            fontSize: '1,2em',
                            marginBottom: 8,
                            marginTop: 16
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={isReferral}
                            onChange={ e => {
                                setIsReferral(!isReferral)
                            }}
                        />
                        Ich wurde von einem Mitglied geworben
                    </label>
                </div>
            )
        }
    }

    function renderReferrer() {
        return (
            <div>
                <div style={{fontSize: '1.5em', marginBottom: '1em'}}>Werbendes Mitglied:</div>
                <label style={{ marginBottom: 16 }}>
                    Vorname
                    <br />
                    <input
                        type="text"
                        value={referralFirstname}
                        className={
                            referralFirstnameEmpty ? 'error' : undefined
                        }
                        onChange={e => {
                            setReferralFirstname(e.target.value)
                        }}
                    />
                </label>
                <label style={{ marginBottom: 16 }}>
                    Nachname
                    <br />
                    <input
                        type="text"
                        value={referralName}
                        className={
                            referralNameEmpty ? 'error' : undefined
                        }
                        onChange={e => {
                            if (e.target.value.length < 1) {
                                setReferralNameEmpty(true)
                            } else {
                                setReferralNameEmpty(false)
                            }
                            setReferralName(e.target.value)
                        }}
                    />
                </label>
                <label style={{ marginBottom: 16 }}>
                    Mitgliedsnummer
                    <br />
                    <input
                        type="text"
                        value={referralMemberId}
                        className={
                            referralMemberIdInvalid ? "error" : ""
                        }
                        onChange={e => {
                            let value = e.target.value
                            if (!value.match(/[0-9]{1,6}/g)) {
                                setReferralMemberIdInvalid(true)
                            } else {
                                setReferralMemberIdInvalid(false)
                            }
                            setReferralMemberId(e.target.value)
                        }}
                    />
                </label>
                <br />
                <div>
                    {renderPresentSize()}
                </div>
                <br />
                <div style={{fontSize: '1.5em', marginBottom: '1em'}}>
                    Meine Daten:
                </div>
            </div>
        )
    }

    function renderReferralInfo() {
        return (
            <div style={{fontSize: '0.8em', marginTop: '1em', marginBottom: '1em'}}>
                Für das Werben eines zahlungspflichtigen Neumitglieds (ab 7 Jahre) erhält der/die Werbende einen Gutschein über 19,05 €, der 1 Jahr lang gültig und im Fanshop einlösbar ist. Für das Werben eines beitragsfreien Neumitglieds (jünger 7 Jahre) wird leider kein Gutschein ausgestellt.
            </div>
        )
    }
    
    function renderConfirmView() {
        return (
            <div>
                <h2>Mitgliedschaft beantragen</h2>
                <div style={{ marginBottom: 8 }}>
                    Hiermit beantrage ich für mich bzw. die
                    angegebenen Personen die passive
                    Mitgliedschaft beim 1. FSV Mainz 05 e.V.
                </div>
                {isPresent ? 
                <div></div> : 
                    <div style={{marginBottom: '1em'}}>
                    Dein erster Beitrag in Höhe von {remainingPrice.toFixed(2)} €
                    bezieht sich auf den Zeitraum {moment().format('MMMM YYYY')+" "} bis 
                    {" "+(moment(nextBilling).subtract(13, 'month').format("MMMM YYYY"))+". "}
                    Ab dem zweiten Vertragsjahr wird dir jährlich im {nextBilling.format('MMMM')+" "} 
                    dein Jahresbeitrag in Höhe von {price.toFixed(2)} € per Lastschrift abgebucht.
                    </div>
                }
                <div className="table-row">
                    <div className="row-title">
                        Anrede&nbsp;
                    </div>
                    <div>{gender}</div>
                </div>
                <div className="table-row">
                    <div className="row-title">Name&nbsp;</div>
                    <div>{firstname + " " + lastname}</div>
                </div>
                <div className="table-row">
                    <div className="row-title">
                        Geburtstag&nbsp;
                    </div>
                    <div>{getDateString(birthdate)}</div>
                </div>
                <div className="table-row">
                    <div className="row-title">
                        Anschrift:&nbsp;
                    </div>
                    <div>
                        {(street ? street + ", " : "") +
                            (zip ? zip + " " : "") +
                            city +
                            ", " +
                            country}
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-title">
                        E-Mail-Adresse:&nbsp;
                    </div>
                    <div>{mail}</div>
                </div>
                <div className="table-row">
                    <div className="row-title">
                        Telefonnummer:&nbsp;
                    </div>
                    <div>{phone}</div>
                </div>
                <div className="table-row">
                    <div className="row-title">
                        Abteilung:&nbsp;
                    </div>
                    <div style={{ maxWidth: "66%" }}>
                        {club.map(el => {
                            if (el.id !== 4) {
                                return el.isChecked
                                    ? el.name + ", "
                                    : "";
                            } else {
                                return el.isChecked
                                    ? el.name
                                    : "";
                            }
                        })}
                    </div>
                </div>
                <div className="table-row">
                    <div className="row-title">
                        Art der Mitgliedschaft:&nbsp;
                    </div>
                    <div>{typeSubscription}</div>
                </div>

                {(presentOne !== "" && presentOne !== undefined) && (
                    <div className="table-row">
                        <div className="row-title">
                            Willkommens- geschenk:&nbsp;
                        </div>
                        <div>
                            Paket&nbsp;"{presentOne}"
                        </div>
                    </div>
                )}
                { (typeSubscription == 'Familienmitgliedschaft' && presentTwo !== "" && presentTwo !== undefined) && (
                    <div className="table-row">
                    <div className="row-title">
                        Zweites Willkommens- geschenk:&nbsp;
                    </div>
                    <div>
                        {presentTwo}
                    </div>
                </div>
                )}
                {typeSubscription ==
                    "Familienmitgliedschaft" && (
                    <div>
                        {!singleParent && (
                            <div className="table-row">
                                <div className="row-title">
                                    Partner:&nbsp;
                                </div>
                                <div>
                                    {partnerFirstname +
                                        " " +
                                        partnerLastname +
                                        ", " +
                                        getDateString(
                                            partnerBirthdate
                                        )}
                                </div>
                            </div>
                        )}
                        {kids.length && (
                            <div className="table-row">
                                <div className="row-title">
                                    Kids:&nbsp;
                                </div>
                                <div>
                                    {kids.map((val, idx) => {
                                        return (
                                            <div key={idx}>
                                                {val.firstname +
                                                    " " +
                                                    val.lastname +
                                                    ", " +
                                                    getDateString(
                                                        val.birthdate
                                                    )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="table-row">
                    <div className="row-title">
                        Kontoinhaber:&nbsp;
                    </div>
                    <div>{accountHolder}</div>
                </div>
                <div className="table-row">
                    <div className="row-title">IBAN:&nbsp;</div>
                    <div>{IBAN}</div>
                </div>
                {/* @Refine: pass data to post request */}
                <label
                    style={{
                        fontSize: 14,
                        marginBottom: 8,
                        marginTop: 16
                    }}
                >
                    <input
                        type="checkbox"
                        checked={paymentAccepted}
                        onChange={e => {setPaymentAccepted(!paymentAccepted)}}
                    />
                    {
                        isPresent ? 
                        "Ich bin mit einer einmaligen Lastschrift von dem von mir angegebenen Konto einverstanden"
                        :
                        "Ich nehme - bis auf Widerruf - am SEPA-Lastschriftverfahren teil"
                    }
                </label>
                {
                    !isPresent && (
                        <div>
                            <label>
                                Bitte beachte unsere Datenschutzhinweise:
                            </label>
                            <label
                                style={{
                                    fontSize: 14,
                                    marginTop: 6,
                                    marginBottom: 12
                                }}
                                className={!isTermsAccepted ? "error" : ""}
                            >
                                <input
                                    type="checkbox"
                                    checked={termsAccepted}
                                    onChange={e => {setTermsAccepted(!termsAccepted)}}
                                />
                                <a
                                    href="https://www.mainz05.de/agbatgb/"
                                    target="_blank"
                                >
                                    Hiermit erkläre ich, die Satzung gelesen
                                    zu haben und bin damit einverstanden
                                </a>
                            </label>
                            <label
                                style={{ fontSize: 14, marginBottom: 16 }}
                            >
                                <input type="checkbox" checked={newsletterAccepted} onChange={e => {setNewsletterAccepted(!newsletterAccepted)}} />
                                Ja, ich möchte den regelmäßigen Newsletter
                                des 1. FSV Mainz 05 e.V. erhalten und mich
                                über die aktuellsten Themen rund um den
                                Verein informieren
                            </label>
                        </div>
                    )
                }
                <div
                    className="price-tag"
                    onClick={() => setShowPriceTable(true)}
                >
                    <div className="label">pro Jahr</div>
                    {price.toFixed(2) + " "}&euro; <br />
                    <span style={{fontSize: '.6em'}}>erster Beitrag: {remainingPrice.toFixed(2) + " "} €</span>
                </div>
                <input
                    type="button"
                    value="Absenden"
                    style={{ marginBottom: 8 }}
                    onClick={submit}
                    disabled={submitDisabled}
                />
                <input
                    type="button"
                    value="Zurück"
                    className="back"
                    style={{ marginBottom: 8 }}
                    onClick={() => setView(2)}
                />
            </div>
        )
    }

    function renderDebitView() {
        return (
            <div>
                <h2>Mitgliedschaft beantragen</h2>
                <div style={{ marginBottom: 16 }}>
                    Abteilung
                    <br />
                    {club.map(val => {
                        return (
                            <label key={val.id}>
                                <input
                                    type="checkbox"
                                    checked={val.isChecked}
                                    value={val.name}
                                    onChange={e =>
                                        handleClubChange(e)
                                    }
                                    disabled={val.isDisabled}
                                />
                                {val.name}
                            </label>
                        );
                    })}
                    {isClubEmpty && (
                        <div className="error">
                            Bitte mindestens eine Abteilung
                            wählen
                        </div>
                    )}
                </div>
                <label>
                    Art der Mitgliedschaft
                    <br />
                    <select
                        defaultValue={typeSubscription}
                        onChange={e => {
                            if (
                                isTypeSubscriptionEmpty &&
                                e.target.value != "0"
                            ) {
                                setIsTypeSubscriptionEmpty(
                                    false
                                );
                            }
                            setTypeSubscription(e.target.value);
                        }}
                        style={{ marginBottom: 16 }}
                        className={
                            isTypeSubscriptionEmpty
                                ? "error"
                                : ""
                        }
                    >
                        <option value="0" disabled hidden>
                            Bitte wählen
                        </option>
                        <option value="Einzelmitgliedschaft">
                            Einzelmitgliedschaft
                        </option>
                        {
                            price > 70 ?
                            <option value="ermäßigte Einzelmitgliedschaft">
                                ermäßigte Einzelmitgliedschaft
                            </option>
                            :
                            undefined
                        }
                        
                        { isPresent ? 
                        undefined :
                        <option value="Familienmitgliedschaft">
                            Familienmitgliedschaft
                        </option>
                        }
                    </select>
                </label>
                <br />
                <br />
                {moment("2022-05-19 00:00:00").diff(moment(), 'second') > 0 && (
                    <div>
                        Willkommensgeschenk auswählen: <br />
                        <br />
                        <Aktion name="presentOne" subscriptionType={typeSubscription} onPresentChange={onPresentOneChange}/>
                        <br />
                    </div>
                )}
                
                { (typeSubscription == "Familienmitgliedschaft" && moment("2022-05-19 00:00:00").diff(moment(), 'second') > 0) && (
                    <div>
                        Zweites Willkommensgeschenk auswählen: <br />
                        <br />
                        <Aktion name="presentTwo" subscriptionType={typeSubscription} onPresentChange={onPresentTwoChange}/>
                    </div>
                )}
                <br />
                {typeSubscription ==
                    "Familienmitgliedschaft" && (
                    <div>
                        <label
                            style={{
                                marginBottom: 16,
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <span style={{ flexBasis: "50%" }}>
                                Anzahl Kinder
                            </span>
                            <input
                                type="number"
                                min="1"
                                max="20"
                                value={kidsNumber}
                                onChange={e => changeKids(e)}
                                style={{ textAlign: "right" }}
                            />
                        </label>
                        {!singleParent && (
                            <div className="family-item">
                                <div
                                    className="cross"
                                    onClick={() =>
                                        setSingleParent(true)
                                    }
                                ></div>
                                <div className="title">
                                    Partner
                                </div>
                                <label>
                                    Vorname
                                    <br />
                                    <input
                                        type="text"
                                        value={partnerFirstname}
                                        className={
                                            isPartnerFirstnameEmpty
                                                ? "error small"
                                                : "small"
                                        }
                                        onChange={e => {
                                            if (
                                                isPartnerFirstnameEmpty &&
                                                e.target.value
                                            ) {
                                                setIsPartnerFirstnameEmpty(
                                                    false
                                                );
                                            }
                                            setPartnerFirstname(
                                                e.target.value
                                            );
                                        }}
                                        style={{
                                            marginBottom: 8
                                        }}
                                    />
                                </label>
                                <label>
                                    Nachname
                                    <input
                                        type="text"
                                        value={partnerLastname}
                                        className={
                                            isPartnerLastnameEmpty
                                                ? "error small"
                                                : "small"
                                        }
                                        onChange={e => {
                                            if (
                                                isPartnerLastnameEmpty &&
                                                e.target.value
                                            ) {
                                                setIsPartnerLastnameEmpty(
                                                    false
                                                );
                                            }
                                            setPartnerLastname(
                                                e.target.value
                                            );
                                        }}
                                        style={{
                                            marginBottom: 8
                                        }}
                                    />
                                </label>
                                <label>
                                    Geschlecht
                                    <select
                                        defaultValue={
                                            partnerGender
                                        }
                                        onChange={e => {
                                            setIsPartnerGenderEmpty(
                                                false
                                            );
                                            setPartnerGender(
                                                e.target.value
                                            );
                                        }}
                                        style={{
                                            marginBottom: 16
                                        }}
                                        className={
                                            isPartnerGenderEmpty
                                                ? "error small"
                                                : "small "
                                        }
                                    >
                                        <option
                                            value="0"
                                            disabled
                                            hidden
                                        >
                                            Bitte wählen
                                        </option>
                                        <option value="männlich">
                                            männlich
                                        </option>
                                        <option value="weiblich">
                                            weiblich
                                        </option>
                                        <option value="divers">
                                            divers
                                        </option>
                                        <option value="ohne Angabe">
                                            ohne Angabe
                                        </option>
                                    </select>
                                </label>
                                <label>
                                    Geburtstag
                                    <DatePicker
                                        dateFormat="dd.MM.yyyy"
                                        selected={
                                            partnerBirthdate
                                        }
                                        onChange={date => {
                                            if (
                                                isPartnerBirthdateEmpty &&
                                                date
                                            ) {
                                                setIsPartnerBirthdateEmpty(
                                                    false
                                                );
                                            }
                                            setPartnerBirthdate(
                                                date
                                            );
                                        }}
                                        className={
                                            isPartnerBirthdateEmpty
                                                ? "error small"
                                                : "small"
                                        }
                                        maxDate={new Date()}
                                        isClearable
                                    />
                                </label>
                            </div>
                        )}
                        {singleParent && (
                            <input
                                type="button"
                                value="Partner hinzufügen"
                                style={{ marginBottom: 16 }}
                                className="btn-light"
                                onClick={() =>
                                    setSingleParent(false)
                                }
                            />
                        )}
                        {kids.map((val, idx) => {
                            const fnameId = `fname-${idx}`;
                            const lnameId = `lname-${idx}`;
                            const dateId = `date-${idx}`;
                            const genderId = `gender-${idx}`;
                            return (
                                <div
                                    className="family-item"
                                    key={`kid-${idx}`}
                                >
                                    {kids.length !== 1 && (
                                        <div
                                            className="cross"
                                            onClick={() =>
                                                removeKid(idx)
                                            }
                                        ></div>
                                    )}
                                    <div className="title">
                                        Kind {idx + 1}
                                    </div>
                                    <label>
                                        Vorname
                                        <br />
                                        <input
                                            type="text"
                                            data-name="firstname"
                                            data-idx={idx}
                                            id={fnameId}
                                            className={
                                                !kids[idx]
                                                    .firstname &&
                                                val.error
                                                    ? "error small"
                                                    : "small"
                                            }
                                            value={
                                                kids[idx]
                                                    .firstname
                                            }
                                            onChange={
                                                handleKidChange
                                            }
                                            style={{
                                                marginBottom: 8
                                            }}
                                        />
                                    </label>
                                    <label>
                                        Nachname
                                        <input
                                            type="text"
                                            data-name="lastname"
                                            data-idx={idx}
                                            id={lnameId}
                                            className={
                                                !kids[idx]
                                                    .lastname &&
                                                val.error
                                                    ? "error small"
                                                    : "small"
                                            }
                                            value={
                                                kids[idx]
                                                    .lastname
                                            }
                                            onChange={
                                                handleKidChange
                                            }
                                            style={{
                                                marginBottom: 8
                                            }}
                                        />
                                    </label>
                                    <label>
                                        Geschlecht
                                        <select
                                            data-name="gender"
                                            data-idx={idx}
                                            id={genderId}
                                            defaultValue={
                                                kids[idx].gender
                                            }
                                            onChange={
                                                handleKidChange
                                            }
                                            style={{
                                                marginBottom: 16
                                            }}
                                            className={
                                                kids[idx]
                                                    .gender ==
                                                    "0" &&
                                                val.error
                                                    ? "error small"
                                                    : "small"
                                            }
                                        >
                                            <option
                                                value="0"
                                                disabled
                                                hidden
                                            >
                                                Bitte wählen
                                            </option>
                                            <option value="männlich">
                                                männlich
                                            </option>
                                            <option value="weiblich">
                                                weiblich
                                            </option>
                                            <option value="divers">
                                                divers
                                            </option>
                                            <option value="ohne Angabe">
                                                ohne Angabe
                                            </option>
                                        </select>
                                    </label>
                                    <label>
                                        Geburtstag
                                        <DatePicker
                                            dateFormat="dd.MM.yyyy"
                                            selected={
                                                kids[idx]
                                                    .birthdate
                                            }
                                            data-name="birthdate"
                                            data-idx={idx}
                                            id={dateId}
                                            onChange={date => {
                                                const updatedKids = [
                                                    ...kids
                                                ];
                                                updatedKids[
                                                    idx
                                                ][
                                                    "birthdate"
                                                ] = date;
                                                setKids(
                                                    updatedKids
                                                );
                                                handleKidChange;
                                            }}
                                            maxDate={new Date()}
                                            isClearable
                                            className={
                                                !kids[idx]
                                                    .birthdate &&
                                                val.error
                                                    ? "error small"
                                                    : "small"
                                            }
                                        />
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
                <label style={{ marginBottom: 16 }}>
                    Kontoinhaber
                    <br />
                    <input
                        type="text"
                        value={accountHolder}
                        className={
                            isAccountHolderEmpty ? "error" : ""
                        }
                        onChange={e => {
                            if (
                                isAccountHolderEmpty &&
                                e.target.value
                            ) {
                                setIsAccountHolderEmpty(false);
                            }
                            setAccountHolder(e.target.value);
                        }}
                    />
                </label>
                <label style={{ marginBottom: 16 }}>
                    IBAN
                    <br />
                    <input
                        type="text"
                        value={IBAN}
                        className={
                            isIBANEmpty || isIBANWrong
                                ? "error"
                                : ""
                        }
                        onChange={e => {
                            let value = e.target.value
                                .toUpperCase()
                                .replace(/\s/g, "");
                            if (
                                (isIBANEmpty ||
                                    setIsIBANWrong) &&
                                value
                            ) {
                                setIsIBANEmpty(false);
                                setIsIBANWrong(false);
                            }
                            setIBAN(value);
                        }}
                    />
                    {isIBANWrong && (
                        <div className="error">
                            Bitte prüfen Sie das IBAN Format
                        </div>
                    )}
                </label>
                <div
                    className="price-tag"
                    onClick={() => setShowPriceTable(true)}
                >
                    <div className="label">pro Jahr</div>
                    {price.toFixed(2) + " "}&euro;
                </div>
                <input
                    type="button"
                    value="Weiter"
                    style={{ marginBottom: 8 }}
                    onClick={() => summary()}
                />
                <input
                    type="button"
                    value="Zurück"
                    className="back"
                    style={{ marginBottom: 8 }}
                    onClick={() => setView(1)}
                />
            </div>
        )
    }

    function renderFinish() {
        if (showFAQ) {
            return renderFAQ();
        }
        if (requestSuccess) {
            if (isPresent) {
                return (
                    <div>
                        <h2>Mitgliedschaft verschenken</h2>
                        <p>
                        Herzlichen Glückwunsch, deine Geschenkmitgliedschaft für das Jahr 2023 wurde erfolgreich erfasst. Wir werden dir in den kommenden Tagen das Begrüßungspaket zusenden, das du dann persönlich dem beschenkten Neumitglied zu Weihnachten oder einem anderen Anlass überreichen kannst. Bei weiteren Fragen kannst du dich gerne an unseren Mitgliederservice per Mail an <a href="mailto:mitgliederservice@mainz05.de">mitgliederservice@mainz05.de</a> oder per Telefon unter 06131 37550 -985 oder -987 wenden, wenn sich die Antwort auf deine Frage nicht in unseren <a href="#" onClick={e => {e.preventDefault(); setShowFAQ(true)}}>FAQs</a> wiederfindet.
                        </p>
                        <input
                            type="button"
                            value="Ok"
                            style={{
                                marginTop: 8,
                                marginBottom: 8
                            }}
                            onClick={() => history.push("/")}
                            // onClick={() => setView(2)}
                        />
                    </div>
                )
            }
           return (
                <div>
                    <h2>Mitgliedschaft beantragen</h2>
                    <p>
                    Dein Mitgliedsantrag ist uns erfolgreich übermittelt worden. Der Mitgliederservice wird deine abgeschlossene Mitgliedschaft binnen der nächsten vier Wochen bearbeiten. Herzlich Willkommen in unserer 05ER Familie!
                    </p>
                    <input
                        type="button"
                        value="Ok"
                        style={{
                            marginTop: 8,
                            marginBottom: 8
                        }}
                        onClick={() => history.push("/")}
                        // onClick={() => setView(2)}
                    />
                </div>
            )
        } else {
           return (
                <div>
                    <h2>Mitgliedschaft beantragen</h2>
                    <p>
                        Es gab einen Fehler bei Überprüfung der
                        Zahlungsdaten.&nbsp;
                        <b>
                            Dein Mitgliedsantrag wurde trotzdem
                            erfolgreich abgesendet.
                        </b>
                        &nbsp;Der Mitgliederservice wird sich binnen
                        4 Wochen mit Dir in Verbindung setzen.
                    </p>
                    <input
                        type="button"
                        value="Ok"
                        style={{
                            marginTop: 8,
                            marginBottom: 8
                        }}
                        onClick={() => history.push("/")}
                        // onClick={() => setView(2)}
                    />
                </div>
            )
        }
    }
    function renderFAQ() {
        return (
            <div>
                <input type="button" value="Schließen" onClick={e => {setShowFAQ(false)}}/> <br /> <br />
                <span style={{fontWeight: 'bold'}}>F: Für welchen Zeitraum ist die verschenkte Mitgliedschaft gültig?</span> <br />
                A: Eine kostenpflichtige, verschenkte Mitgliedschaft ist vom 01.01.2023 bis 31.12.2023 gültig. Eine kostenfreie, verschenkte Mitgliedschaft (Kinder unter 7 Jahren) ist bis zum Erreichen des 7. Geburtstages gültig. Eine Abfrage, ob die Mitgliedschaft verlängert werden soll, erfolgt zeitnah vor dem Ende des Gültigkeitszeitraums. <br />
                <br />
                <span style={{fontWeight: 'bold'}}>F: In welchem Zeitraum kann ich die Mitgliedschaft verschenken?</span><br />
                A: Die Aktion „Geschenkmitgliedschaft“ läuft ab sofort bis zum 18.12.2023 bei Postversand und bis zum 22.12.2023 bei Abholung des Geschenkpakets in der Geschäftsstelle. <br />
                <br />
                <span style={{fontWeight: 'bold'}}>F. Was erhalte ich als Schenker? </span> <br />
                A. Als Schenker einer kostenpflichtigen Neu-Mitgliedschaft erhältst du das Begrüßungspaket zum Weiterschenken.<br />
                <br />
                <span style={{fontWeight: 'bold'}}>F. Was erhält der Beschenkte?</span> <br />
                A. Der Beschenkte erhält die Mitgliedschaft vom 01.01.2023-31.12.32023 mit allen Mitgliedschaftsvorteilen, die der jeweiligen Preiskategorie entsprechen, die Begrüßungsbox für Neumitglieder sowie exklusiv ein Paar gebrandete Mainz 05 Fleecehandschuhe. (Die Beigabe ist auf 150 Stück limitiert) <br />
                <br />
                <span style={{fontWeight: 'bold'}}>F. Was passiert, wenn ich versehentlich jemanden beschenke, der bereits Mitglied ist?</span> <br />
                A. In dem Fall melden wir uns bei dir und überweisen dir entweder das Geld zurück oder gewähren dem beschenkten Mitglied ein betragsfreies Jahr.<br />
                <br />
                <span style={{fontWeight: 'bold'}}>F. Kann die Mitgliedschaft auch storniert werden, wenn der/die Beschenkte nicht Mitglied werden möchte?</span> <br />
                A. In diesem Fall ist eine Mitteilung an mitgliederservice@mainz05.de erforderlich.  <br />
                <br />
                <span style={{fontWeight: 'bold'}}>F. Wohin kann ich mich mit weiteren Fragen wenden?</span> <br />
                A. Für weitere Fragen steht dir unser Mitgliederservice per E-Mail unter mitgliederservice@mainz05.de oder telefonisch unter 06131 37550 -985 oder -987 zur Verfügung. <br />
            <br />
            <input type="button" value="Schließen" onClick={e => {setShowFAQ(false)}}/>
        </div>
        )
    }

    function renderCameFromTextfield() {
        return (
            <div>
                <textarea />
            </div>
        )
    }

    function renderPresentSize() {
        //TODO: Dropdown scheiß
        if (moment().diff(endeRefGes, 's')<0) {
            return (
                <div style={{marginBottom: '2em'}}>
                    <label>Welche Größe sollen die Handschuhe für das werbende Mitglied haben?</label>
                    <select value={handschuhSize} onChange={onHandschuhChange} className={handschuhError ? "error": ""}>
                        <option value={"M"}>M</option>
                        <option value={"S"}>S</option>
                        <option value={"L"}>L</option>
                    </select>
                    
                </div>
            )
        }
        return undefined
    }

    function renderDropDown() {
        //TODO: Dropdown scheiß
        return (
            <div style={{marginBottom: '2em'}}>
                <label>Wie bist du auf die Mitgliedschaft aufmerksam geworden?</label>
                <select value={cameFrom} onChange={onCameFromchange} className={cameFromError ? "error" : ""}>
                    <option value={""} disabled hidden>Bitte wählen</option>
                    {cameFromOptions.map((o,key) => (<option key={key} value={o}>{o}</option>))}
                </select>
                
            </div>
        )
    }

    function renderDefaultRequest() {

        if (showFAQ) {
            return renderFAQ();
            // return (
            //     <div>
            //         <input type="button" value="Schließen" onClick={e => {setShowFAQ(false)}}/> <br /> <br />
            //         <span style={{fontWeight: 'bold'}}>F: Für welchen Zeitraum ist die verschenkte Mitgliedschaft gültig?</span> <br />
            //         A: Eine kostenpflichtige, verschenkte Mitgliedschaft ist vom 01.01.2022 bis 31.12.2022 gültig. Eine kostenfreie, verschenkte Mitgliedschaft (Kinder unter 7 Jahren) ist bis zum Erreichen des 7. Lebensjahres gültig. Eine Abfrage, ob die Mitgliedschaft verlängert werden soll, erfolgt zeitnah vor dem Ende des Gültigkeitszeitraums. <br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F: In welchem Zeitraum kann ich die Mitgliedschaft verschenken?</span><br />
            //         A: Die Aktion „Geschenkmitgliedschaft“ läuft ab sofort bis zum 19.12.2021. <br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Was erhalte ich als Schenker? </span> <br />
            //         A. Als Schenker einer kostenpflichtigen Mitgliedschaft erhältst du eine Dankeschön-Überraschung, die wir dir zusammen mit dem Mitgliedsbegrüßungsgeschenk zusenden. Zusätzlich erhältst du einen 30%-Rabatt auf ein Trikot der Saison 2022/23 (nur, wenn die Mitgliedschaft in der rot-weißen Woche abgeschlossen wurde) und nimmst am Gewinnspiel teil. Als Schenker einer kostenfreien Mitgliedschaft nimmst du nur am Gewinnspiel teil.<br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Erhalte ich zusätzlich zum 30% Nachlass weitere 10% Rabatt auf das Trikot, wenn ich als Schenker Mitglied bin?</span><br />
            //         A. Nein, ein weiterer Rabatt ist nicht möglich<br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Was erhält der Beschenkte?</span> <br />
            //         A. Der Beschenkte erhält ein Mitgliederbegrüßungspaket und profitiert ab dem 01.01.2022 von unseren Mitgliedervorteilen. Das Begrüßungspaket senden wir dir (Schenker) zu, damit du es als Überraschung persönlich überreichen kannst. <br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Was gibt es beim Gewinnspiel zu gewinnen?</span> <br />
            //         A. Du kannst eines von 3 signierten Fastnachtstrikots gewinnen.  <br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Wie werden die Gewinner beim Gewinnspiel ermittelt? </span><br />
            //         A. Pro verschenkte Mitgliedschaft erhältst du ein Los. Die 3 Gewinner werden nach Ende des Aktionszeitraums nach dem 19.12.2021 ermittelt und kontaktiert. <br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Was passiert, wenn ich versehentlich jemanden beschenke, der bereits Mitglied ist?</span> <br />
            //         A. In dem Fall melden wir uns bei dir und überweisen dir entweder das Geld zurück oder gewähren dem beschenkten Mitglied ein betragsfreies Jahr. <br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Kann die Mitgliedschaft auch storniert werden, wenn der/die Beschenkte nicht Mitglied werden möchte?</span> <br />
            //         A. In diesem Fall ist eine Mitteilung an mitgliederservice@mainz05.de erforderlich.  <br />
            //         <br />
            //         <span style={{fontWeight: 'bold'}}>F. Wohin kann ich mich mit weiteren Fragen wenden?</span> <br />
            //         A. Für weitere Fragen steht dir unser Mitgliederservice per E-Mail unter mitgliederservice@mainz05.de oder telefonisch unter 06131 37550 -985 oder -987 zur Verfügung. <br />
            //     <br />
            //     <input type="button" value="Schließen" onClick={e => {setShowFAQ(false)}}/>
            // </div>
            // )
        }
        return (
            <div>
                <h2>Mitgliedschaft beantragen</h2>
                <br />
                {renderCheckboxes()}
                <br />
                { isPresent && (
                    <div stlye={{marginBottom: '1.5em'}}>
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            setShowFAQ(true)
                        }}>
                            FAQ's</a> zur Geschenkaktion
                    </div>
                )}
                {
                isPresent && (
                    <div style={{fontSize: '1.5em', marginBottom: '1.5em', marginTop: '1.5em'}}>
                        Meine Daten:<br />
                    </div>
                    )
                }
                {isPresent && renderGiverInfo()}
                {
                isPresent && 
                    ( 
                        <div style={{fontSize: '1.5em', marginBottom: '1.5em'}}>
                            <br />
                            Ich möchte beschenken: 
                            <br />
                        </div>
                    )
                }

                {
                isReferral && 
                    renderReferrer() 
                }
                <label style={{ marginBottom: 16 }}>
                    Vorname
                    <br />
                    <input
                        type="text"
                        value={firstname}
                        className={
                            isFirstnameEmpty ? "error" : ""
                        }
                        onChange={e => {
                            if (
                                isFirstnameEmpty &&
                                e.target.value
                            ) {
                                setIsFirstnameEmpty(false);
                            }
                            setFirstname(e.target.value);
                        }}
                    />
                </label>
                <label style={{ marginBottom: 16 }}>
                    Nachname
                    <br />
                    <input
                        type="text"
                        className={
                            isLastnameEmpty ? "error" : ""
                        }
                        value={lastname}
                        onChange={e => {
                            if (
                                isLastnameEmpty &&
                                e.target.value
                            ) {
                                setIsLastnameEmpty(false);
                            }
                            setLastname(e.target.value);
                        }}
                    />
                </label>
                <label style={{ marginBottom: 16 }}>
                    Land
                    <br />
                    <select
                        defaultValue={country}
                        onChange={e => {
                            setCountry(e.target.value);
                        }}
                    >
                        {Object.keys(countries).map(k => {
                            return (
                                <option
                                    value={countries[k]}
                                    key={k}
                                >
                                    {countries[k]}
                                </option>
                            );
                        })}
                    </select>
                </label>
                <div
                    style={{
                        marginBottom: 16,
                        display: "flex"
                    }}
                >
                    <label
                        style={{
                            paddingRight: 8,
                            flexBasis: "33%"
                        }}
                    >
                        PLZ
                        <br />
                        <input
                            type="text"
                            value={zip}
                            className={
                                isZIPWrong ? "error" : ""
                            }
                            onChange={e => {
                                if (isZIPWrong) {
                                    setIsZIPWrong(false);
                                }
                                setZip(
                                    e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    )
                                );
                            }}
                        />
                        {isZIPWrong && (
                            <div
                                className="error"
                                style={{
                                    width: "100%"
                                }}
                            >
                                Bitte PLZ prüfen
                            </div>
                        )}
                    </label>

                    <label style={{ flexBasis: "66%" }}>
                        Stadt
                        <br />
                        <input
                            type="text"
                            value={city}
                            onChange={e => {
                                if (
                                    isCityEmpty &&
                                    e.target.value
                                ) {
                                    setIsCityEmpty(false);
                                }
                                setCity(e.target.value);
                            }}
                            className={
                                isCityEmpty ? "error" : ""
                            }
                        />
                    </label>
                </div>
                <label style={{ marginBottom: 16 }}>
                    Straße, Hausnummer
                    <br />
                    <input
                        type="text"
                        value={street}
                        onChange={e =>
                            setStreet(e.target.value)
                        }
                    />
                </label>
                <label>
                    Geschlecht
                    <br />
                    <select
                        defaultValue={gender}
                        onChange={e => {
                            if (
                                isGenderEmpty &&
                                e.target.value != "0"
                            ) {
                                setIsGenderEmpty(false);
                            }
                            setGender(e.target.value);
                        }}
                        style={{ marginBottom: 16 }}
                        className={isGenderEmpty ? "error" : ""}
                    >
                        <option value="0" disabled hidden>
                            Bitte wählen
                        </option>
                        <option value="männlich">
                            männlich
                        </option>
                        <option value="weiblich">
                            weiblich
                        </option>
                        <option value="divers">divers</option>
                        <option value="ohne Angabe">
                            ohne Angabe
                        </option>
                    </select>
                </label>
                <label style={{ marginBottom: 16 }}>
                    Geburtstag
                    <DatePicker
                        dateFormat="dd.MM.yyyy"
                        selected={birthdate}
                        onChange={date => {
                            if (isBirthdateEmpty && date) {
                                setIsBirthdateEmpty(false);
                            }
                            let d1 = new Date(Date.now());
                            let d2 = new Date(birthdate);

                            let age =
                                d1.getFullYear() -
                                d2.getFullYear();
                            if (
                                typeSubscription ==
                                    "ermäßigte Einzelmitgliedschaft" &&
                                age <= 67
                            ) {
                                setTypeSubscription("0");
                            }
                            setBirthdate(date);
                        }}
                        className={
                            isBirthdateEmpty ? "error" : ""
                        }
                        maxDate={new Date()}
                        isClearable
                    />
                </label>
                <label>
                    E-Mail-Adresse
                    <br />
                    <input
                        type="text"
                        style={{ marginBottom: 4 }}
                        value={mail}
                        className={
                            isMailOrPhoneEmpty ? "error" : ""
                        }
                        onChange={e => {
                            if (
                                isMailOrPhoneEmpty &&
                                e.target.value
                            ) {
                                setIsMailOrPhoneEmpty(false);
                            }
                            setMail(e.target.value);
                        }}
                    />
                    <div
                        style={{
                            marginBottom: 16,
                            fontSize: 14,
                            lineHeight: 1.1,
                            fontStyle: "italic",
                            opacity: 0.6
                        }}
                    >
                        Die E-Mail-Adresse wird für die
                        Gewährung von Mitgliedervorteilen in den
                        Ticket- und Merchandisingshops sowie für
                        die Mitgliederkommunikation benötigt
                    </div>
                </label>
                <label>
                    Telefonnummer
                    <br />
                    <input
                        type="email"
                        style={{ marginBottom: 16 }}
                        value={phone}
                        className={
                            isMailOrPhoneEmpty && !mail
                                ? "error"
                                : ""
                        }
                        onChange={e => {
                            if (
                                isMailOrPhoneEmpty &&
                                e.target.value
                            ) {
                                setIsMailOrPhoneEmpty(false);
                            }
                            setPhone(e.target.value);
                        }}
                    />
                </label>
                <br />
                { moment().diff(endeGeschenk, 's') < 0 && renderPresentSize()}
                {renderDropDown()}
                {isReferral && 
                    renderReferralInfo()
                }
                
                <div
                    className="price-tag"
                    onClick={() => setShowPriceTable(true)}
                >
                    <div className="label">pro Jahr</div>
                    {price.toFixed(2) + " "}&euro;
                </div>
                <input
                    type="button"
                    value="Weiter"
                    style={{ marginBottom: 8 }}
                    onClick={()=>{
                        next()
                    }}
                />
                <input
                    type="button"
                    value="Zurück"
                    className="back"
                    style={{ marginBottom: 8 }}
                    onClick={() => history.push("/")}
                />
            </div>
        )
    }
    
    return (
        <div>
            <CSSTransition
                in={showPriceTable}
                timeout={200}
                classNames="modal"
                unmountOnExit
            >
                <div className="modal-window">
                    <div className="window">
                        <div
                            className="cross"
                            onClick={() => setShowPriceTable(false)}
                        ></div>
                        <img src="/images/price.jpg" />
                    </div>
                    <div
                        className="surface"
                        onClick={() => setShowPriceTable(false)}
                    ></div>
                </div>
            </CSSTransition>
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={
                        view == 1
                            ? "1"
                            : view == 2
                            ? "2"
                            : view == 3
                            ? "3"
                            : "4"
                    }
                    timeout={160}
                    classNames="membership"
                >
                    <div>
                        {
                        view == 1 && 
                            renderDefaultRequest()
                        }
                        {
                        view == 2 && 
                            renderDebitView()
                        }
                        {
                        view == 3 &&
                            renderConfirmView() 
                        }
                        {
                        view == 4 &&
                            renderFinish()
                        }
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default Mitgliedschaft;
