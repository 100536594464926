import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    useLocation
} from "react-router-dom";
import Home from "./Home";
import Kontakt from "./Kontakt";
import Mitgliedschaft from "./Mitgliedschaft";
import Auskunft from "./Auskunft";
import Kontaktdaten from "./Kontaktdaten";

function Main(props) {
    return (
        <main className="content">
            <Router>
                <Link to="/">
                    <header>
                        <div className="logo"></div>
                        <h1>mein.mainz05.de</h1>
                    </header>
                </Link>
                <div>
                    <Switch>
                        <Route path="/kontakt">
                            <Kontakt />
                        </Route>
                        <Route path="/mitgliedschaft" exact>
                            <Mitgliedschaft type="default" />
                        </Route>
                        <Route path="/mitgliedschaft/werben">
                            <Mitgliedschaft type="referral" />
                        </Route>
                        <Route path="/mitgliedschaft/schenken">
                            <Mitgliedschaft type="present" />
                        </Route>
                        <Route path="/dsgvo-auskunft">
                            <Auskunft />
                        </Route>
                        <Route path="/kontaktdaten">
                            <Kontaktdaten />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
            </Router>
            <div className="links">
                <a href="http://www.mainz05.de/datenschutz/">Datenschutz</a>
                <a href="https://www.mainz05.de/impressum/">Impressum</a>
                <a href="http://www.mainz05.de/agbatgb/">AGB/ATGBs</a>
            </div>
        </main>
    );
}

if (document.getElementById("app")) {
    let app = document.getElementById('app')
    ReactDOM.render(<Main />, document.getElementById("app"));
}
